






















import {Component, Vue, Inject, Prop, Watch} from 'vue-property-decorator';
import Util from '../../../../lib/util'
import AbpBase from '../../../../lib/abpbase'
import CreateShippingOrderJob from '../../../../store/entities/ordercontext/createshippingorderjob'

@Component
export default class CreateOrderJobForm extends AbpBase {
    @Prop({type: Boolean, default: false}) value: boolean;
    shippingOrderJob: CreateShippingOrderJob = new CreateShippingOrderJob();

    private tobTemplateList: any;

    data() {
        return {
            tobTemplateList: []
        }
    };

    async mounted() {
        this.tobTemplateList = await this.$store.dispatch('jobTemplate/lookUp');
    }


    save() {
        (this.$refs.orderJobForm as any).validate(async (valid: boolean) => {
            if (valid) {
                await this.$store.dispatch({
                    type: 'order/createJob',
                    data: this.shippingOrderJob
                });
                (this.$refs.orderJobForm as any).resetFields();
                this.$emit('save-success');
                this.$emit('input', false);
            }
        })
    }

    cancel() {
        (this.$refs.orderJobForm as any).resetFields();
        this.$emit('input', false);
    }

    visibleChange(value: boolean) {
        if (!value) {
            this.$emit('input', value);
        } else {
            this.shippingOrderJob = Util.extend(true, {}, this.$store.state.order.createJob);
        }
    }

    documentRule = {
        name: [{required: true, message: this.L('FieldIsRequired', undefined, this.L('Name')), trigger: 'blur'}]
    }
}















































import {Component, Vue, Inject, Prop, Watch} from 'vue-property-decorator';
import Util from '@/lib/util'
import AbpBase from '@/lib/abpbase'
import PageRequest from '@/store/entities/page-request'
import SortingModel from "@/store/entities/sortingmodel";
import OrderCustomerForm from "@/views/setting/OrderContext/Order/OrderCustomerForm.vue";
import CustomerOrder from '@/store/entities/ordercontext/customershippingorder'
import AdminOrder from '@/store/entities/ordercontext/adminshippingorder'
import CreateJobForm from './createorderjob.vue'
import CreateShippingOrderJob from "@/store/entities/ordercontext/createshippingorderjob";
import abpWeb = abp.localization.abpWeb;

class OrderPageRequest extends PageRequest {
  statusId: number;
}

@Component({
  components: {OrderCustomerForm, CreateJobForm}
})
export default class Order extends AbpBase {

  pagerequest: OrderPageRequest = new OrderPageRequest();


  private statuses;

  data() {
    return {
      statuses: []
    }
  }

  async changeSort(data) {
    if (this.pagerequest.sorting == null) {
      this.pagerequest.sorting = [];
    }
    let item = this.pagerequest.sorting.find(x => x.fieldName === data.key)
    if (item == null) {
      let sortingModel = new SortingModel();
      sortingModel.fieldName = data.key;
      sortingModel.isDescendingDirection = data.order === "desc";
      this.pagerequest.sorting.push(sortingModel)
    } else {
      item.isDescendingDirection = data.order === "desc";
    }
    await this.getpage()
  }

  async edit(id: number) {
    if (window.abp.auth.hasPermission('Pages.AdminOrder')) {
      await this.$router.push({name: 'admin-order', params: {orderId: id.toString()}});
    } else {
      this.$store.commit('order/editCustomerShippingOrder', await this.$store.dispatch({
        type: 'order/getCustomer',
        data: id
      }));
      this.editModalShow = true;
    }
  }

  createModalShow: boolean = false;
  editModalShow: boolean = false;
  createJobModalShow: boolean = false;

  get list() {
    return this.$store.state.order.list;
  };

  get loading() {
    return this.$store.state.order.loading;
  }

  createJob(id: number) {
    let shippingOrderJob = new CreateShippingOrderJob();
    shippingOrderJob.shippingOrderId = id;
    this.$store.commit('order/setShippingOrderJob', shippingOrderJob);
    this.createJobModalShow = true;
  }

  checkPermissions(permission: string) {
    return window.abp.auth.hasPermission(permission);
  }


  async clone(id: number) {
    await this.$store.dispatch({
      type: 'order/clone',
      data: id
    })
    await this.getpage();
  }


  async create() {
    if (window.abp.auth.hasPermission('Pages.AdminOrder')) {
      await this.edit(await this.$store.dispatch({
        type: 'order/createForAdmin',
        data: new AdminOrder()
      }))
    } else {
      await this.edit(await this.$store.dispatch({
        type: 'order/createForCustomer',
        data: new CustomerOrder()
      }))
    }
  }

  async pageChange(page: number) {
    this.$store.commit('order/setCurrentPage', page);
    await this.getpage();
  }

  async search() {
    await this.pageChange(1);
  }

  async pagesizeChange(pagesize: number) {
    this.$store.commit('order/setPageSize', pagesize);
    await this.getpage();
  }

  async getpage() {
    this.pagerequest.maxResultCount = this.pageSize;
    this.pagerequest.skipCount = (this.currentPage - 1) * this.pageSize;

    await this.$store.dispatch({
      type: 'order/getAll',
      data: this.pagerequest
    })
  }

  get pageSize() {
    return this.$store.state.order.pageSize;
  }

  get totalCount() {
    return this.$store.state.order.totalCount;
  }

  get currentPage() {
    return this.$store.state.order.currentPage;
  }

  async goToJob(jobId: number) {
    if (this.checkPermissions('Pages.EditJob')) {
      await this.$router.push({name: 'job-form', params: {jobId: jobId.toString()}});
    } else {
      await this.$router.push({name: 'job-client-form', params: {jobId: jobId.toString()}});
    }
  }

  columns = [{
    title: this.L('No'),
    key: 'number',
    width: 75,
    fixed: 'left'
  }, {
    title: this.L('ReadyDate'),
    key: 'readyDate',
    width: 200,
    sortable: 'custom',
    render: (h: any, params: any) => {
      return h('span', params.row.readyDate === null ? "" : new Date(params.row.readyDate).toLocaleDateString())
    }
  }, {
    title: this.L('Client'),
    key: 'client',
    width: 200,
    sortable: 'custom'
  }, {
    title: this.L('Supplier'),
    key: 'supplier',
    width: 200,
    sortable: 'custom',
    render: (h, params) => {
      let row = params.row;
      return h('label', {
        style: {
          color: row.isCustomSupplier ? '#ff0008' : '#515a6e'
        }
      }, row.supplier);
    }
  }, {
    title: this.L('Consignee'),
    key: 'consignee',
    width: 200,
    sortable: 'custom',
    render: (h, params) => {
      let row = params.row;
      return h('label', {
        style: {
          color: row.isCustomConsignee ? '#ff0008' : '#515a6e'
        }
      }, row.consignee);
    }
  }, {
    title: this.L('Origin'),
    key: 'origin',
    width: 200,
    sortable: 'custom'
  }, {
    title: this.L('Destination'),
    key: 'destination',
    width: 200,
    sortable: 'custom'
  }, {
    title: this.L('Status'),
    key: 'status',
    width: 200
  }, {
    title: this.L('Po#'),
    key: 'po',
    width: 200,
    sortable: 'custom'
  }, {
    title: this.L('Job created from order'),
    key: 'jobId',
    fixed: 'right',
    width: 150,
    render: (h: any, params: any) => {
      return h('div', [
        h('Button', {
          props: {
            type: 'primary',
            size: 'small',
            width: '100%'
          },
          style: {
            marginRight: '5px',
            visibility: params.row.jobId === null ? 'hidden' : 'visible'
          },
          on: {
            click: async () => {
              await this.goToJob(params.row.jobId);
            }
          }
        }, params.row.jobId === null ? '' : params.row.jobId.toString())
      ])
    }
  },
    {
      title: this.L('Actions'),
      key: 'Actions',
      fixed: 'right',
      width: this.checkPermissions('Pages.CloneShippingOrder') ? 330 : 150,
      render: (h: any, params: any) => {
        return h('div', [
          h('Button', {
            props: {
              type: 'primary',
              size: 'small',
            },
            style: {
              marginRight: '5px',
              visibility: (this.checkPermissions('Pages.CloneShippingOrder') && params.row.status === "Pending") ? 'visible' : 'hidden'
            },
            on: {
              click: async () => {
                await this.mail(params.row.id);
              }
            }
          }, this.L('Mail')),
          h('Button', {
            props: {
              type: 'primary',
              size: 'small'
            },
            style: {
              marginRight: '5px',
              visibility: (this.checkPermissions('Pages.CloneShippingOrder')) ? 'visible' : 'hidden'
            },
            on: {
              click: async () => {
                await this.clone(params.row.id);
              }
            }
          }, this.L('Clone')),
          h('Button', {
            props: {
              type: 'primary',
              size: 'small'
            },
            style: {
              marginRight: '5px',
              visibility: (params.row.status !== "Cancelled" && params.row.status !== "Convert to job" && this.checkPermissions('Pages.CreateJobFromShippingOrder')) ? 'visible' : 'hidden'
            },
            on: {
              click: async () => {
                this.createJob(params.row.id);
              }
            }
          }, this.L('Create job')),
          h('Button', {
            props: {
              type: 'primary',
              size: 'small'
            },
            style: {
              marginRight: '5px',
              visibility: params.row.status === "Convert to job" || params.row.status === "Shipping request" || params.row.status === "Pending" ? 'visible' : 'hidden'
            },
            on: {
              click: () => {
                this.edit(params.row.id);
              }
            }
          }, this.L('Edit')),
          h('Button', {
            props: {
              type: 'error',
              size: 'small'
            },
            style: {
              visibility: !this.checkPermissions('Pages.CloneShippingOrder') && params.row.status !== "Shipping request" ? 'hidden' : 'visible'
            },
            on: {
              click: async () => {
                this.$Modal.confirm({
                  title: this.L('Tips'),
                  content: this.L('Delete Order Confirm'),
                  okText: this.L('Yes'),
                  cancelText: this.L('No'),
                  onOk: async () => {
                    await this.$store.dispatch({
                      type: 'order/delete',
                      data: params.row
                    })
                    await this.getpage();
                  }
                })
              }
            }
          }, this.L('Delete'))
        ])
      }
    }]

  async mail(shippingOrderId) {
    window.open(await this.$store.dispatch({type: 'order/mailToLink', data: shippingOrderId}));
  }

  async created() {
    await this.getpage();
    this.statuses = await this.$store.dispatch('order/shippingStatuses');
  }
}
